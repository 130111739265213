<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-format-list-bulleted-type"
      title="Action Items"
      class="px-5 py-3"
    >
      <v-simple-table>
              <thead>
              <tr>
                  <th class="primary--text">
                  Details
                  </th>
                  <th class="text-right primary--text">
                  Count
                  </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td>Purchase agreements awaiting Homeowner signature</td>
                  <td class="text-right">
                      {{count_pa}}
                  </td>
              </tr>
              <tr>
                  <td>Home selections awaiting Homeowner signature</td>
                  <td class="text-right">
                      {{count_vs}}
                  </td>
              </tr>
              <tr>
                  <td>Vendor selections awaiting Homeowner signature</td>
                  <td class="text-right">
                      {{count_hs}}
                  </td>
              </tr>
              </tbody>
          </v-simple-table>
    </base-material-card>



  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AgentDashboard',
  data () {
      return {
        count_pa : 0,
        count_vs : 0,
        count_hs : 0,
      }
    },
  created () {
    this.getAlerts();
  },
  methods:{
    getAlerts() {
          axios
          .get(process.env.VUE_APP_GET_DASHBOARD_ALERTS_URL,
          {
          params: {
              board: 'agent_dashboard',
              agent_email: localStorage.getItem('agentEmail')
          }
          })
          .then(response => {
              
              if (response.data)
              {
                  var result = response.data
                  this.count_pa = result['count_pa']
                  this.count_vs = result['count_vs']
                  this.count_hs = result['count_hs']
              }
          })
          .catch(error => {
          console.log(error)
          })
      },
  }
}
</script>


<style scoped>
.v-data-table table thead tr th {
    font-weight: 300;
    font-size: 17px;
    padding: 0 8px;
}
</style>